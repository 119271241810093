<template>
  <a class="skip-to-content" :href="`#${contentId}`"> Skip to Content </a>
</template>

<script>
  export default {
    computed: {
      contentId() {
        return this.$config.public.mainContentId
      },
    },
  }
</script>

<style lang="scss" scoped>

  .skip-to-content {
    display: inline-block;
    text-decoration: none;
    padding: $base-spacing * 4 $base-spacing * 8;
    margin: $base-spacing;
    opacity: 0.1;
    background: white;
    color: inherit;
    border: 2px solid $gray;

    font-weight: bold;
    border-radius: $border-radius;
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity 170ms ease-in;
    opacity: 0;
    pointer-events: none;
    &:focus {
      pointer-events: initial;
      outline: none;
      opacity: 1;
      z-index: 200;
    }
  }
</style>
