<template>
  <div class="cart-item-info-wrap">
    <div class="cart-item-info">
      <img
        v-if="item.variant"
        :src="item.variant.productImageUrl"
        role="presentation"
        class="cart-item-info-image"
        loading="lazy"
      />
      <div class="ci-info">
        <div class="ci-name">
          {{ item.variant.name }}
        </div>
        {{ item.variant.optionsText }}
      </div>
      <div class="ci-price">
        <span class="quant">
          <span class="quant-label"> Qty:</span> {{ item.quantity }}
        </span>
        <span v-if="item.displayAmount === item.displayTotalBeforeTax">
          {{ item.displayAmountAdded }}
        </span>
        <span v-else class="discounted-price">
          <span class="price price-highlighted">{{
            item.displayDiscountedAmount
          }}</span>
          <s class="price">
            {{ item.displayAmountAdded }}
          </s>
        </span>
      </div>
    </div>
    <BackorderNotice
      v-if="item.backorderedQuantity > 0"
      :text="backOrderString()"
      class="cart-item-backorder-notice"
    />
  </div>
</template>

<script setup lang="ts">
  interface CartItem {
    id: string
    quantity: number
    price: string
    amount: string
    displayPrice: string
    displayAmount: string
    freeSample: boolean
    displayTotalBeforeTax: string
    quantityAdded: number
    displayDiscountedAmount: string
    displayAmountAdded: string
    backorderedQuantity: number
    variant: SolidusProductVariant
  }

  const props = defineProps<{
    item: CartItem
  }>()

  const fullBackOrderMessage = (quantity: number, days: number) =>
    `${quantity} items expected to ship within ${days} days.`

  const partialBackOrderMessage = (
    quantityAdded: number,
    backorderedQuantity: number,
    estimatedDays: number,
  ) =>
    `${
      quantityAdded - backorderedQuantity
    } items ready to ship. ${backorderedQuantity} item expected to ship within ${estimatedDays} days.`

  const { item } = props
  const backOrderString = () => {
    if (item.backorderedQuantity >= item.quantityAdded) {
      return fullBackOrderMessage(
        item.quantityAdded,
        item.variant.estimatedDaysToFillBackorder,
      )
    } else {
      return partialBackOrderMessage(
        item.quantityAdded,
        item.backorderedQuantity,
        item.variant.estimatedDaysToFillBackorder,
      )
    }
  }
</script>

<style lang="scss" scoped>
  .cart-item-info-wrap {
    border-bottom: 2px solid $silver;
    padding: $base-spacing * 3 0;
    &:last-child {
      border-bottom: none;
    }
  }
  .cart-item-info {
    display: flex;
  }

  .cart-item-info-image {
    width: $space-m;
    height: $space-m;
    margin-right: $space-s;
    border-radius: $border-radius;
    transition: box-shadow 120ms ease-in-out;
    box-shadow: $shadow;
  }
  .ci-info {
    color: $gray-500;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
  }
  .ci-name {
    color: $onyx;
    @include type("h5");
    .cs-mini & {
      @include type("sm");
    }
  }
  .ci-price {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $base-spacing * 2;
    font-weight: bold;
    @include viewport("mini") {
      display: none;
    }
  }
  .quant {
    margin-right: $base-spacing * 10;
  }
  .quant-label {
    font-weight: normal;
    color: $gray-600;
  }
  .price {
    color: $gray-text;
    &.price-highlighted {
      color: $blue-text;
    }
  }
  .discounted-price {
    display: flex;
    text-align: right;
    flex-direction: column;
  }

  .cart-item-backorder-notice {
    padding-top: $base-spacing * 2;
  }
</style>
