<template>
  <nuxt-link
    to="/cart/"
    class="cart-badge"
    :aria-label="
      itemCount === 0
        ? 'Your empty shopping cart'
        : `Your shopping Cart, you have ${itemCount} items in your Cart`
    "
  >
    <SvgIcon class="shopping-cart-icon" icon="shopping-cart-alt" />

    <div :class="['item-count-wrap', updated && 'updated']">
      <transition name="count-anim">
        <span v-if="itemCount > 0" class="item-count">
          {{ itemCount }}
        </span>
      </transition>
    </div>
  </nuxt-link>
</template>

<script>
  export default {
    props: {
      itemCount: {
        type: Number,
        default: 0,
      },
    },
    fetchKey: "cart-icon",
    data() {
      return {
        updated: false,
      }
    },
    watch: {
      itemCount() {
        this.updated = true
        setTimeout(() => {
          this.updated = false
        }, 220)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .cart-badge {
    display: flex;
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 10px;

    border: none;

    .shopping-cart-icon {
      display: block;
      fill: none;
      color: $onyx;
      width: $base-spacing * 6;
      height: $base-spacing * 6;
      transition: stroke 170ms ease-in-out;
    }
    .item-count-wrap {
      position: absolute;
      top: $base-spacing * -1;
      right: $base-spacing * -1;
      transition: transform 220ms ease-in-out;
      &.updated {
        transform: scale(1.2);
      }
    }
    .item-count {
      text-align: center;
      font-weight: bold;
      line-height: $base-spacing * 6;
      display: block;
      font-family: $font-family-default;

      color: white;
      text-decoration: none;
      font-style: bold;
      font-weight: bold;
      background: $blue-text;

      height: $base-spacing * 6;
      min-width: $base-spacing * 6;
      border-radius: 100px;
      padding: 0 $base-spacing;
      will-change: transform;
      box-sizing: border-box;
      backface-visibility: hidden;
    }
    .item-count-number {
      display: inline-block;
    }
    &:hover {
      svg {
        color: $blue;
      }
    }
  }

  .count-anim-enter-active {
    transition:
      transform 170ms cubic-bezier(0, 0.88, 0.43, 1.68),
      opacity 170ms ease-in-out;
  }
  .count-anim-leave-active {
    transition:
      transform 170ms cubic-bezier(0.86, 0.21, 0.91, 0.62),
      opacity 170ms ease-in-out;
  }
  .count-anim-enter,
  .count-anim-leave-to {
    transform: scale(0.5);
    opacity: 0;
  }
</style>
