<template>
  <a href="https://support.candlescience.com" :class="CLASS_LINK">Help</a>
  <nuxt-link v-if="loggedIn" :class="CLASS_LINK" to="/account/orders/">
    Account
  </nuxt-link>
  <nuxt-link v-if="!loggedIn" to="/signup/" :class="CLASS_LINK">
    Register
  </nuxt-link>
  <nuxt-link v-if="!loggedIn" to="/login/" :class="CLASS_LINK">Login</nuxt-link>
  <CartIconWithItemCount :item-count="itemCount" />

  <nuxt-link
    :to="loggedIn ? '/account/orders/' : '/login/'"
    aria-label="Login into your User Account"
    :class="[CLASS_BUTTON, '-order-1 mini:order-auto']"
  >
    <SvgIcon icon="account" :class="CLASS_ICON" />
  </nuxt-link>
  <button
    :class="[CLASS_BUTTON, '-order-2 mini:order-auto']"
    aria-label="Menu"
    @click="updateNavigationState(!mobileNavIsOpen)"
  >
    <SvgIcon v-show="!mobileNavIsOpen" icon="menu" :class="CLASS_ICON" />
    <SvgIcon v-show="mobileNavIsOpen" icon="x" :class="CLASS_ICON" />
  </button>
</template>

<script>
  import { mapState, mapActions } from "vuex"
  import { mapState as pmapState } from "pinia"
  import { useCartStore } from "~/pinia/cart"

  import CartIconWithItemCount from "~/components/CartIconWithItemCount"

  export default {
    components: {
      CartIconWithItemCount,
    },

    setup() {
      const { logout } = useLogout()

      const CLASS_LINK =
        "hidden sm:block text-md p-0 leading-[44px] font-bold text-onyx-500 hover:text-blue-text"
      const CLASS_BUTTON =
        "block sm:hidden p-[10px] text-onyx-500 bg-transparent hover:text-blue-shape border-0"
      const CLASS_ICON = "w-6 h-6 block"

      return {
        CLASS_LINK,
        CLASS_BUTTON,
        CLASS_ICON,
        logout,
      }
    },

    computed: {
      ...mapState("nav", {
        mobileNavIsOpen: (state) => state.mobileNavIsOpen,
      }),
      ...pmapState(useCartStore, ["itemCount"]),
      ...pmapState(useAuthStore, ["loggedIn"]),
    },

    watch: {
      $route() {
        const mobileRouteUpdate = () => {
          this.$store.commit("nav/setNavigationState", false)
        }
        this.mobileNavIsOpen && mobileRouteUpdate()
      },
    },
    mounted() {
      useCartStore().loadCart()
    },
    methods: {
      ...mapActions("nav", ["updateNavigationState"]),
    },
  }
</script>
