<template>
  <li
    class="row"
    :aria-haspopup="element.nestedElements && element.nestedElements.length > 0"
    :aria-expanded="selectedRow != null"
  >
    <template v-for="element in element.nestedElements">
      <component
        :is="element.name"
        v-if="!selectedRow || selectedRow == element.id"
        :key="element.id"
        :element="element"
        :selected="selectedRow == element.id"
        @selected="selectRow(element.id, $event)"
        @subtreeBack="selectRow(null)"
      />
    </template>
  </li>
</template>

<script>
  import MobileNavSubtree from "./MobileNavSubtree"
  import MobileNavCard from "./MobileNavCard"
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    components: {
      main_menu_submenu: MobileNavSubtree,
      main_menu_card: MobileNavCard,
    },
    mixins: [AlchemyElement],
    data() {
      return {
        selectedRow: null,
      }
    },
    methods: {
      selectRow(id, e) {
        window.scroll(0, 0)
        e ? (this.selectedRow = id) : (this.selectedRow = null)
        this.$emit("selected", e)
      },
    },
  }
</script>

<style lang="scss">

  .row {
    display: flex;
    flex-flow: row wrap;
    &:focus {
      outline: none;
    }
  }
</style>
