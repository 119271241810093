<template>
  <ul role="navigation" :aria-label="`submenu for ${title}`" class="menu">
    <div ref="menu" class="menu-content">
      <NavColumn
        v-for="column in alchemyElement.nestedElements"
        :key="column.id"
        :column="column"
      />
    </div>
  </ul>
</template>

<script setup lang="ts">
  import NavColumn from "./NavColumn.vue"

  const props = defineProps<{
    alchemyElement: AlchemyMainMenuItem
  }>()

  const { getValue } = useAlchemy()

  const title = getValue(props.alchemyElement, "title")
</script>

<style lang="scss" scoped>
  .menu {
    padding: 0;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    &:before {
      content: "";
      background: white;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      width: 100vw;
      max-width: $max-outbreak-width;
      transform: translateX(-50%);
      box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05);
    }
  }
  .menu-content {
    position: relative;
    @include type("xs");
    padding: $gutter;
    padding-top: $space-s;
    max-width: $content-max-width;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    gap: $gutter;
  }
</style>
