<template>
  <div class="footer-wrapper">
    <Container>
      <div
        v-if="showStudy"
        class="bg-purple-text rounded p-4 sm:p-6 text-purple-tint"
      >
        <h1 class="m-0 mb-4 font-headline">What isn't working?</h1>
        <p class="mt-0 mb-6 text-lg">
          Success is often a long journey marked by learning and setbacks. We
          aim to learn from your stories to enhance our products and services.
          Please share your anonymous experiences with us
        </p>
        <Nuxt-Link
          target="_blank"
          class="inline-block bg-purple-tint text-purple-text rounded p-2 sm:p-3 hover:no-underline hover:bg-purple-100"
          to="https://collector.sensemaker-suite.com/collector?projectID=cb78ae74-35db-482b-ab1f-08f48f693680"
        >
          Participate
        </Nuxt-Link>
      </div>
      <footer class="cs-footer">
        <div>
          <NewsletterInput class="footer_newsletter" />
          <SocialIcons
            class="footer-socials"
            tiktok
            facebook
            instagram
            pinterest
            youtube
          />
        </div>
        <div class="footer-navigation">
          <div v-for="item in navItems" :key="item.name">
            <h2 class="footer-headline">
              {{ item.name }}
            </h2>
            <ul class="footer-list">
              <li v-for="listItem in item.children" :key="listItem.name">
                <NuxtLink :to="listItem.linkUrl" class="footer-link">
                  {{ listItem.name }}
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div class="footer-info">
        © {{ year }}, CandleScience Inc. All Rights Reserved,
        <NuxtLink to="/terms-of-sale/">Terms of Sale</NuxtLink>,
        <NuxtLink to="/privacy/">Privacy Policy</NuxtLink>
      </div>
    </Container>
  </div>
</template>

<script setup>
  import { deserializePages } from "@alchemy_cms/json_api"

  const { api } = useApi()
  const { data: navItems } = useLazyAsyncData(
    async () => {
      const data = await api(`/jsonapi/layout_pages.json`, {
        params: {
          include: "all_elements.ingredients,language.menu_items",
          "filter[page_layout_eq]": "api_footer_menu",
        },
      })
      return data
    },
    {
      transform(data) {
        const deserializedPage = deserializePages(data)[0]
        const footerSubmenu = deserializedPage.elements.find(
          (element) => element.name === "footer_submenu",
        )
        const subMenu = footerSubmenu?.ingredients.find(
          (ingredient) => ingredient.role === "submenu",
        )
        return subMenu?.node.children
      },
    },
  )

  const authStore = useAuthStore()
  const showStudy = computed(() => authStore.user?.featureFlags?.makerStudy)

  const year = computed(() => new Date().getFullYear())
</script>

<style lang="scss">
  // hide zendesk on print
  iframe#launcher {
    @media print {
      display: none;
    }
  }
</style>

<style lang="scss" scoped>
  .footer-wrapper {
    background: white;
    padding: 1px 0;
  }

  .cs-footer {
    padding: $gutter 0;
    width: 100%;
    grid-gap: $space-s;

    display: flex;
    @include viewport("mini", "sm") {
      flex-direction: column;
    }

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: $base-spacing * 16;
      @include viewport("m") {
        grid-gap: $base-spacing * 15;
      }
      @include viewport("mini", "sm", "m") {
        grid-template-columns: 1fr;
        grid-gap: $base-spacing * 20;
      }
    }
    @media print {
      display: none;
    }
  }

  .footer-socials {
    justify-content: flex-start;
  }
  .footer-headline {
    @include type("h5");
    color: $gray-700;
    margin: 0;
    margin-bottom: $base-spacing * 2;
  }
  .footer-navigation {
    flex-grow: 1;
    @include type("sm");
    color: $gray-text;
    width: 100%;
    grid-gap: $space-m;

    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    @include viewport("mini", "sm", "m") {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-auto-flow: initial;
      grid-gap: $base-spacing * 10;
    }
  }
  .footer-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .footer-link {
    display: inline-flex;
    align-items: center;
    min-height: $base-spacing * 7;
    text-decoration: none;
    color: inherit;
  }

  .footer_newsletter {
    color: $gray-text;
    margin-bottom: $base-spacing * 5;
  }
  .footer-info {
    color: $gray-text;
    a {
      color: $red-text;
    }
    @media print {
      display: none;
    }
  }
</style>
