<template>
  <div class="item">
    <button
      v-if="!titleOnly && !selected"
      :class="['title', titleOnly && 'titleOnly']"
      @click.stop="$emit('click')"
    >
      <span class="title-toggle">
        <SvgIcon
          icon="chevron-right"
          class="title-toggle-icon"
          aria-hidden="true"
        />
      </span>
      <img v-if="!selected" class="image" :src="image" :alt="imageAlt" />
      {{ title }}
      <span v-if="tag !== 'none'" class="title-tag"> {{ tag }} </span>
    </button>
    <div v-if="selected" class="selected-item-wrap">
      <button
        class="selected-item-backbutton"
        :aria-label="`Collapse ${title} menu`"
        @click.stop="$emit('backButtonClick')"
      >
        <SvgIcon icon="chevron-left" class="selected-item-backbutton-icon" />
      </button>
      <span class="selected-item-title">
        {{ title }}
      </span>
      <NuxtLink
        v-if="href"
        :to="href"
        class="selected-item-link"
        @click="viewAllClick"
      >
        View all
      </NuxtLink>
    </div>

    <NuxtLink v-if="titleOnly" class="title titleOnly" :to="href">
      <img class="image" :src="image" :alt="imageAlt" />
      {{ title }}
    </NuxtLink>
  </div>
</template>

<script>
  import { mapActions } from "vuex"

  export default {
    props: {
      title: { type: String, required: true },
      image: { type: String, default: null },
      imageAlt: { type: String, default: null },
      titleOnly: { type: Boolean, default: false },
      selected: { type: Boolean, default: false },
      tag: { type: String, default: null },
      href: { type: String, default: null },
    },
    computed: {},
    methods: {
      ...mapActions("nav", ["resetNav"]),
      viewAllClick() {
        if (this.$route.path === this.href) {
          this.resetNav()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .item {
    display: flex;
    position: relative;
  }

  /* Link */
  .title {
    flex: 1;
    display: flex;
    white-space: nowrap;
    @include type("h5");
    grid-gap: $base-spacing * 4;
    border: none;
    background: transparent;
    align-items: center;
    padding: $base-spacing * 2 $gutter;
    color: inherit;
    text-decoration: none;
    &:focus {
      outline: none;
      color: $red;
    }
    &:hover {
      background-color: $silver;
    }
  }
  .image {
    flex-shrink: 0;
    background-size: cover;
    width: 48px;
    border-radius: $border-radius;
    height: 48px;
    object-fit: cover;
    transition: box-shadow 120ms ease-in-out;
    box-shadow: $shadow;
  }

  /* Button */
  @keyframes indicator {
    50% {
      transform: translateX($base-spacing * 2);
    }
  }
  .title-toggle {
    cursor: pointer;
    color: $gray-400;
    @include type("h5");
    width: $base-spacing * 10;
    height: $base-spacing * 10;
    display: flex;

    align-items: center;
    justify-content: center;
    background: white;
    position: absolute;
    top: 50%;
    right: $base-spacing * 4;
    border-radius: $border-radius;
    border: none;
    margin: 0;
    margin-top: $base-spacing * -5;
    .title:focus & {
      outline: none;
      color: $red;
      .title-toggle-icon {
        animation: indicator 350ms ease-in 2;
      }
    }
    @include viewport("mini") {
      right: $base-spacing * 3;
    }
  }
  .title-toggle-icon {
    width: $base-spacing * 6;
    height: $base-spacing * 6;
  }

  .title-tag {
    background: $green-text;
    color: white;
    @include type("sm");
    border-radius: $border-radius;
    padding: 0 $base-spacing;
  }

  .selected-item-wrap {
    border-bottom: 1px solid $gray-200;
    width: 100%;
    padding: $base-spacing * 2 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .selected-item-title {
    font-weight: bold;
  }
  .selected-item-link {
    color: inherit;
    @include type("sm");
  }
  .selected-item-backbutton {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: none;
    border: none;
    width: $base-spacing * 12;
    padding: 0;
    margin: 0 $space-s;
    box-sizing: border-box;
    &-icon {
      width: 24px;
      height: 24px;
      color: $gray;
    }
  }
</style>
