<template>
  <header ref="header" :class="['header', mobileNavIsOpen && 'nav-open']">
    <SkipToContent />
    <Container class="bg-white relative" :vertical-padding="false">
      <div :class="CLASS_HEADER">
        <NuxtLink
          class="mx-auto sm:mx-0 block"
          to="/"
          aria-label="Candlescience"
          :prefetch="false"
        >
          <Logo />
        </NuxtLink>

        <button
          ref="mobileSearchToggle"
          aria-label="Toggle Search Bar"
          :class="CLASS_BUTTON"
          @click="toggleSearchBar"
        >
          <SvgIcon icon="search" aria-hidden="true" :class="CLASS_ICON" />
        </button>

        <div
          ref="searchWrap"
          class="page-header-search-wrap"
          :class="searchBarInFocus && 'focusmode'"
        >
          <SearchBar
            v-if="showNewSearch"
            ref="searchBar"
            @blur="searchBarBlur"
            @focus="searchBarFocus"
          />
          <SearchBarOld
            v-else
            ref="searchBar"
            @blur="searchBarBlur"
            @focus="searchBarFocus"
          />
        </div>
        <SubNavigation />
      </div>
      <MobileNavigation
        class="page-header-mobile-nav"
        :nav-elements="menuData"
      />
      <Navigation
        v-if="menuData"
        class="page-header-nav"
        :nav-elements="menuData"
      />
    </Container>
    <button
      v-if="mobileNavIsOpen"
      aria-label="Close Mobile Overlay"
      class="backdrop"
      @click="backdropClick"
    />
  </header>
</template>

<script setup lang="ts">
  import { deserializePages } from "@alchemy_cms/json_api"

  const store = useStore()
  const { api } = useApi()
  const authStore = useAuthStore()
  const { $mq } = useNuxtApp()

  const tmpNavDims = ref({})
  const transitionOngoing = ref(false)
  const searchBarInFocus = ref(false)

  const header = ref(null)
  const searchBar: Ref<HTMLDivElement | null> = ref(null)
  const searchWrap: Ref<HTMLDivElement | null> = ref(null)
  const mobileSearchToggle = ref(null)

  const mobileNavIsOpen = computed(() => store.state.nav.mobileNavIsOpen)
  const showNewSearch = computed(() => authStore.user?.featureFlags?.newSearch)

  const CLASS_HEADER =
    "flex align-center py-3 -mx-2 mini:py-5 mini:mx-0 mini:gap-4 sm:gap-8 bg-white sm:p-0 sm:pt-6"
  const CLASS_BUTTON =
    "block sm:hidden p-[10px] text-onyx-500 bg-transparent hover:text-blue-shape border-0"
  const CLASS_ICON = "w-6 h-6 block"

  function toggleSearchBar() {
    searchBarFocus()
    setTimeout(() => {
      if (searchBar.value) {
        searchBar.value.focus()
      }
    }, 50)
  }

  function backdropClick() {
    store.commit("nav/setNavigationState", false)
  }

  function searchBarBlur(e: FocusEvent) {
    const relatedTarget = e.relatedTarget as HTMLElement
    if (
      !transitionOngoing.value &&
      !relatedTarget?.classList?.contains("submit-search") &&
      !relatedTarget?.classList?.contains("search-input")
    ) {
      transitionOngoing.value = true
      searchBarInFocus.value = false

      setTimeout(() => {
        transitionOngoing.value = false
      }, 280)
    }
  }

  function searchBarFocus() {
    if (($mq.mini.value || $mq.sm.value) && !transitionOngoing.value) {
      transitionOngoing.value = true
      const bodyElement = document.querySelector("body")
      const bodyDims = bodyElement ? bodyElement.getBoundingClientRect() : null

      const searchWrapDims = searchWrap.value
        ? searchWrap.value.getBoundingClientRect()
        : null

      if (searchWrapDims && bodyDims) {
        tmpNavDims.value = {
          left: searchWrapDims.left,
          right: bodyDims.width - searchWrapDims.right,
        }
      }

      searchBarInFocus.value = true

      setTimeout(() => {
        transitionOngoing.value = false
      }, 50)
    }
  }

  const { data: menuData } = await useAsyncData(
    "api_dropdown_menu",
    () => {
      return api(`/jsonapi/layout_pages.json`, {
        params: {
          include: "all_elements.ingredients,language.menu_items",
          "filter[page_layout_eq]": "api_dropdown_menu",
        },
      })
    },
    {
      default: () => [],
      transform: (data) => {
        const apiDropdownMenu = deserializePages(
          data,
        )[0] as AlchemyApiDropdownMenu
        return apiDropdownMenu.elements as AlchemyMainMenuItem[]
      },
    },
  )
</script>

<style lang="scss" scoped>
  .header {
    display: flex;
    background: white;
    flex-direction: column;
    position: relative;
    z-index: 100;

    .container {
      position: static;
    }

    @media print {
      display: none;
    }

    &.nav-open {
      overscroll-behavior: none;
      overflow: auto;
      position: fixed;
      bottom: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.5);
    }
    @include viewport("sm", "mini") {
      top: 0;
      right: 0;
      left: 0;
      position: sticky;
    }
  }

  .page-header-search-wrap {
    flex: 1;
    z-index: 110;
    padding-inline: $base-spacing * 2;

    @include viewport("lg") {
      display: block;
    }

    @include viewport("mini", "sm") {
      padding-inline: $gutter;
      opacity: 0;
      visibility: hidden;
      position: fixed;
      pointer-events: none;
      transition: all 280ms ease;
      width: initial;
      top: $base-spacing * 3;
      left: 0;
      right: 0;
      transform: translateY($base-spacing * -4);
    }

    &.focusmode {
      visibility: visible;
      pointer-events: all;

      opacity: 1;
      transform: translateY(0);
    }
  }

  .page-header-nav.page-header-nav {
    @include viewport("mini", "sm") {
      display: none;
    }
  }
  .page-header-mobile-nav {
    display: none;
    @include viewport("mini", "sm") {
      display: block;
    }
  }

  .loading-skeletons {
    width: 80%;
    padding: 0 $space-s;
    height: $base-spacing * 18;
    padding: 0 $base-spacing * 8;
    grid-gap: $space-s;
    align-items: center;
    display: flex;
    @include viewport("mini", "sm") {
      display: none;
    }
  }

  @keyframes load {
    50% {
      opacity: 0.7;
    }
  }

  .loading-skeleton {
    height: 16px;
    border-radius: $border-radius;
    animation: load 1.5s ease-in-out infinite;
    flex: 1;
    background: $gray-200;
  }

  .backdrop {
    border: none;
    background: none;
    height: 100%;
  }
</style>
