<template>
  <CsDialog
    ref="confirmDialog"
    :title="confirmModalData.title"
    :confirm-label="confirmModalData.confirmLabel"
    :cancel-label="confirmModalData.cancelLabel"
    @cancel="onCancel"
    @confirm="onConfirm"
    @close="onClose"
  >
    <p class="m-0 mb-4 text-lg text-pretty">
      {{ confirmModalData.text }}
    </p>
  </CsDialog>
</template>

<script setup lang="ts">
  import CsDialog from "../CsDialog.vue"
  import { useNotificationStore } from "~/pinia/notifications"

  const notificationStore = useNotificationStore()

  const confirmModalData = computed(() => notificationStore.confirmModalData)
  const confirmAction = computed(() => notificationStore.modalConfirmAction)
  const cancelAction = computed(() => notificationStore.modalCancelAction)

  const confirmDialog: Ref<InstanceType<typeof CsDialog> | null> = ref(null)

  const onCancel = () => {
    confirmDialog.value?.close()
  }

  const onClose = () => {
    cancelAction.value?.()
  }

  const onConfirm = () => {
    confirmAction.value?.()
    confirmDialog.value?.close()
  }

  notificationStore.$onAction(({ name }) => {
    switch (name) {
      case "confirmAction":
        confirmDialog.value?.openModal()
        break
    }
  }, true)
</script>
