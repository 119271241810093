<template>
  <div class="cs" :class="uiStore?.blockScroll && 'blockscroll'">
    <Announcer />
    <ClientOnly>
      <CartModal />
    </ClientOnly>
    <ConfirmModal />
    <SiteWideNotice />
    <PageHeader />
    <NotificationArea />

    <main :id="contentId">
      <slot />
    </main>
    <PageFooter />
  </div>
</template>

<script lang="ts" setup>
  import { deserialize } from "@alchemy_cms/json_api"
  import { PRECONNECT_LIST } from "~/lib/preconnectUrls"

  const { api } = useApi()

  const saleStore = useSaleStore()

  useHead({
    titleTemplate: (titleChunk) => {
      return titleChunk ? `${titleChunk} - CandleScience` : "CandleScience"
    },
    title: "Candle Making Supplies",
    htmlAttrs: {
      lang: "en",
    },
    link: [...PRECONNECT_LIST],
  })

  const contentId = useRuntimeConfig().public.mainContentId

  const store = useStore()
  const cartStore = useCartStore()
  const authStore = useAuthStore()
  const uiStore = useUiStore()

  const resetNav = () => {
    store.dispatch("nav/resetNav")
  }

  const route = useRoute()

  const fetchOneOzSales = async () =>
    await api<JsonapiStoreResponse>(`/jsonapi/store?include=one_oz_sales`).then(
      deserialize,
    )

  watch(
    () => route.name,
    () => {
      resetNav()
    },
  )

  // We check for active sale on every route change
  watch(
    () => route.path,
    async () => {
      const saleData = await fetchOneOzSales()
      saleStore.setSale(saleData.oneOzSales[0])
    },
  )

  const MAX_ITEMS_FOR_CART = 25
  const { $csGtm, $router } = useNuxtApp()

  onMounted(async () => {
    // We check for active sale on page load
    const saleData = await fetchOneOzSales()
    saleStore.setSale(saleData.oneOzSales[0])

    if (route.query.addvariants) {
      const itemsForCart: ItemsForCart = route.query.addvariants
        .split(",")
        .reduce((acc, variantId) => {
          acc[variantId] = 1
          return acc
        }, {})
      if (Object.keys(itemsForCart).length <= MAX_ITEMS_FOR_CART) {
        cartStore.addToCart(itemsForCart)
      }
      await $router.replace({ query: null })
    }

    $csGtm.trackEvent({
      event: "content-view",
      "content-name": route.path,
    })

    if (typeof klaviyo !== "undefined") {
      klaviyo.identify({
        $email: authStore.user?.email,
      })
    }
  })
</script>

<style lang="scss">
  @import "~/assets/base";

  html {
    scroll-padding-top: $space-m;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:has(.blockscroll) {
      overflow: hidden;
    }
  }

  body {
    margin: 0;
    background: $sand-400;
  }

  #main-content {
    &:focus-visible,
    &:focus {
      outline: none;
    }
  }
  .scroll-block {
    @include viewport("sm", "mini") {
      touch-action: none;
      -webkit-overflow-scrolling: none;
      overflow: hidden;
      overscroll-behavior: none;
    }
  }
  .cs {
    position: relative;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: $onyx;
    @include type("sm");
    font-family: $font-family-default;
    font-feature-settings: "cv03", "cv04", "cv11"; // Shows alternate characters for a,6,9
    max-width: $max-outbreak-width;
    background: white;
    margin: 0 auto;
    padding-bottom: 120px;

    @media print {
      font-size: 12pt;
    }

    strong {
      font-weight: bold;
    }
  }
  ::-moz-selection {
    background: $blue;
    color: white;
  }
  ::selection {
    color: white;
    background: $blue;
  }
  body {
    margin: 0;
  }
  a {
    color: var(--link);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .cs-dark {
    color: white;
  }

  .alchemy-fallback-element {
    max-width: $content-max-width;
    margin: 0 auto;
    box-sizing: border-box;
    padding: $gutter;
  }
</style>
