<template>
  <div class="card-wrap">
    <NuxtLink
      :class="[
        'mobile-card',
        darkText && 'text-dark',
        overlay && 'overlay',
        centerText && 'center-text',
      ]"
      :to="href"
      :style="{ backgroundImage: `url(${image})` }"
    >
      <span class="mobile-card-text">
        {{ title }}
      </span>
    </NuxtLink>
  </div>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    data() {
      return {
        title: this.getValue("title"),
        href: this.getValue("link"),
        image: this.getValue("image"),
        darkText: this.getValue("dark_text"),
        overlay: this.getValue("overlay"),
        centerText: this.getValue("center_text"),
      }
    },
  }
</script>

<style lang="scss" scoped>
  .card-wrap {
    display: inline-flex;
    box-sizing: border-box;
    flex: 1;
    padding: $base-spacing * 4;
    height: $base-spacing * 40;
    flex-direction: column;
    &:only-child {
      width: 100%;
      display: flex;
      height: $base-spacing * 60;
      @include type('h3');
      padding: $base-spacing * 4;
      padding-bottom: 0;
    }
  }
  .mobile-card {
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: $border-radius;
    box-shadow: $shadow;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    text-shadow: 2px 2px rgba($onyx, 0.5);
    color: white;
    background-size: cover;
    background-position: center center;
    transition: transform 170ms ease-in-out, box-shadow 170ms ease-in-out;
    backface-visibility: hidden;
    padding: 1.5em $space-s;

    &:hover {
      transform: scale(1.03) translate3d(0, 0, 0) perspective(1px);
      box-shadow: $hover-shadow;
    }
    &:focus {
      outline: none;
      box-shadow: $focus-shadow;
    }
    &:last-child {
      margin-left: 0;
    }
    &:only-child {
      margin-right: 0;
    }

    &.text-dark {
      color: $onyx;
      text-shadow: 1px 1px rgba(white, 0.5);
    }

    &.center-text {
      align-items: center;
    }

    &.overlay {
      &:after {
        content: " ";
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.25);
      }
      &.text-dark:after {
        background: rgba(255, 255, 255, 0.25);
      }
    }
  }
  .mobile-card-text {
    position: relative;
    z-index: 10;
  }
</style>
