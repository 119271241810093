<template>
  <div ref="searchResultsEl" class="search-bar">
    <form role="search" class="search-bar-wrap" @submit="onSubmit">
      <div class="search-bar-icon-wrap" aria-hidden="true">
        <SvgIcon icon="search" class="search-bar-icon" />
      </div>

      <input
        id="search-term"
        ref="searchInputElement"
        v-model="searchInput"
        :aria-activedescendant="ariaActiveDescendent"
        :aria-label="inputAriaLabel"
        :aria-expanded="canShowResults"
        :aria-owns="ariaOwns"
        aria-autocomplete="list"
        aria-haspopup="listbox"
        aria-controls="category-suggestions article-suggestions help-suggestions product-suggestions"
        :class="['search-input', canShowResults && 'open']"
        autocomplete="off"
        placeholder="Search Fragrances and Supplies"
        type="text"
        name="q"
        @input="onInput"
        @focus="onInputFocus"
        @blur="onInputBlur"
        @keydown="onInputKeydown"
      />

      <button
        v-if="$mq.mini"
        class="submit-search"
        type="button"
        @click="onSearch"
        @blur="onInputBlur"
      >
        Search
      </button>
    </form>

    <div
      v-if="searchInput && allResults?.length > 0 && canShowResults"
      id="search-results"
      role="listbox"
      :class="['dropdown', canShowResults && 'open']"
    >
      <div class="dropdown-columns">
        <Container class="columns-container" :vertical-padding="false">
          <div class="search-column">
            <NuxtLink
              class="column-title"
              :to="`/search?q=${searchInput}&filter=articles&page=1`"
              tabindex="-1"
            >
              Categories
              <SvgIcon icon="chevron-right" />
            </NuxtLink>
            <div
              id="category-suggestions"
              class="search-results"
              role="listbox"
              aria-labelledby="search-term"
            >
              <ResultLink
                v-for="(result, i) in rawCategoryResults"
                :id="`category-suggestion-${i}`"
                :key="result.id"
                :class="[focusedId === result.id && 'active']"
                :link="result.permalink"
                :copy="result.highlightedName"
                @result-click="() => onResultClick(result)"
              />
              <div v-if="!rawCategoryResults?.length">No Category results</div>
            </div>
          </div>
          <div class="search-column">
            <NuxtLink
              class="column-title"
              :to="`/search?q=${searchInput}&filter=content&page=1`"
              tabindex="-1"
            >
              Content
              <SvgIcon icon="chevron-right" />
            </NuxtLink>
            <div
              id="article-suggestions"
              class="search-results"
              role="listbox"
              aria-labelledby="search-term"
            >
              <ResultLink
                v-for="(result, i) in rawPageResults"
                :id="`article-suggestion-${i}`"
                :key="result.id"
                :class="[focusedId === result.id && 'active']"
                :link="result.permalink"
                :copy="result.highlightedName"
                @result-click="() => onResultClick(result)"
              />
              <div v-if="!rawPageResults?.length">No Page results</div>
            </div>
          </div>
          <div class="search-column">
            <NuxtLink
              class="column-title"
              :to="`/search?q=${searchInput}&filter=help&page=1`"
              tabindex="-1"
            >
              Help
              <SvgIcon icon="chevron-right" />
            </NuxtLink>
            <div
              id="support-suggestions"
              class="search-results"
              role="listbox"
              aria-labelledby="search-term"
            >
              <ResultLink
                v-for="(result, i) in rawHelpResults"
                :id="`help-suggestion-${i}`"
                :key="result.id"
                :class="[focusedId === result.id && 'active']"
                :link="result.permalink"
                :copy="result.highlightedName"
                @result-click="() => onResultClick(result)"
              />
              <div v-if="!rawHelpResults?.length">No Help results</div>
            </div>
          </div>
        </Container>
      </div>

      <div class="dropdown-products">
        <Container class="products-container" :vertical-padding="false">
          <NuxtLink
            class="column-title"
            :to="`/search?q=${searchInput}&filter=products&page=1`"
            tabindex="-1"
          >
            Products
            <SvgIcon icon="chevron-right" />
          </NuxtLink>
          <div
            id="product-suggestions"
            class="products-list"
            role="listbox"
            aria-labelledby="search-term"
          >
            <ProductThumb
              v-for="(result, i) in rawProductResults"
              :id="`product-suggestion-${i}`"
              :key="result.id"
              :href="result.permalink"
              class="product-result"
              :class="[focusedId === result.id && 'active']"
              role="option"
              tabindex="-1"
              :srcset="result.imageSrcSet"
              sizes="36px"
              :src="result.imagePath || result.fallbackImage"
              :alt="result.name"
              :name="result.name"
              :price-range="[result.minPrice, result.maxPrice]"
              :rating="Math.round(result.rating)"
              @on-click="() => onResultClick(result)"
            />

            <div v-if="!rawProductResults?.length">No Product results</div>
          </div>

          <Button
            ref="resultsBtn"
            color="blue"
            size="medium"
            :class="['query-button']"
            type="button"
            tabindex="-1"
            @click="onSearch"
          >
            All results for "{{ searchInput }}"
          </Button>
        </Container>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import ResultLink from "./ResultLink.vue"
  import AlgoliaClient from "~/lib/algolia-client.js"
  import parseRawSearchResult from "~/lib/parse-raw-search-result.js"
  import debounce from "~/lib/debounce"
  import { useNotificationStore } from "~/pinia/notifications"

  const { $mq, $csGtm } = useNuxtApp()
  const route = useRoute()
  const notificationStore = useNotificationStore()

  const emit = defineEmits(["blur", "focus"])

  type filterValueMapType = {
    products: string[]
    help: string[]
    articles: string[]
    categories: string[]
  }

  const FILTER_VALUE_MAP: filterValueMapType = {
    products: ["content_type:product"],
    help: ["content_type:help"],
    articles: ["content_type:content"],
    categories: ["content_type:category"],
  }

  type ariaSectionIdMapType = {
    products: string
    help: string
    articles: string
    categories: string
  }

  const ariaSectionIdMap: ariaSectionIdMapType = {
    products: "product-suggestion",
    help: "help-suggestion",
    articles: "article-suggestion",
    categories: "category-suggestion",
  }

  const config = useRuntimeConfig()
  const algoliaClient = new AlgoliaClient(20, {
    algoliaAppId: config.public.ALGOLIA_APPLICATION_ID,
    algoliaApiKey: config.public.ALGOLIA_API_KEY,
  })

  const searchInputElement = ref<HTMLInputElement>()
  const searchInput = ref("")
  const cachedSearchInput = ref("")
  const canShowResults = ref(false)
  const focusedIndex = ref(-1)
  const ariaActiveDescendent = ref("")
  const ariaOwns = ref("")
  const resultsBtn = ref<HTMLButtonElement | null>(null)
  const searchResultsEl = ref<HTMLDivElement | null>(null)

  const allRawResults = ref<any[]>([])
  const allResults = ref<any[]>([])
  const rawProductResults = ref<any[]>([])
  const rawHelpResults = ref<any[]>([])
  const rawPageResults = ref<any[]>([])
  const rawCategoryResults = ref<any[]>([])

  // Input Focus & Blur
  // ----

  function clickedInSearchDropdown(e: FocusEvent) {
    const target = e.target as HTMLElement
    return !!target.closest(".search-bar")
  }

  function blurredWithinDropdown(e: FocusEvent) {
    const target = e.relatedTarget as HTMLElement
    return target ? !!target.closest(".search-bar") : false
  }

  function onInputFocus(e: FocusEvent) {
    emit("focus", e)
    canShowResults.value = true
    window.addEventListener("click", onWindowClick)
  }

  function onWindowClick(e: MouseEvent) {
    if (clickedInSearchDropdown(e)) {
      return
    }

    closeDropdown()
    window.removeEventListener("click", onWindowClick)
  }

  function onInputBlur(e: FocusEvent) {
    if (!blurredWithinDropdown(e)) {
      closeDropdown()
    }
  }

  const closeDropdown = () => {
    canShowResults.value = false
    focusedIndex.value = -1
    searchInputElement.value?.blur()
  }

  // Dropdown Query
  // ----

  const onInput = async () => {
    if (searchInput.value.length > 0 && !$mq.mini.value) {
      await search()
    } else {
      canShowResults.value = false

      $csGtm.trackEvent({
        event: "search-input-clear",
        query: cachedSearchInput.value,
      })
    }

    cachedSearchInput.value = searchInput.value
  }

  function createQueries(query: string) {
    const searchIndexName = "Cs_SearchResult"

    // Order the queries as they appear and should be focused
    return [
      {
        indexName: searchIndexName,
        query,
        params: {
          filters: "content_type:category",
          hitsPerPage: 5,
        },
      },
      {
        indexName: searchIndexName,
        query,
        params: {
          filters: "content_type:content",
          hitsPerPage: 5,
        },
      },
      {
        indexName: searchIndexName,
        query,
        params: {
          filters: "content_type:help",
          hitsPerPage: 5,
        },
      },
      {
        indexName: searchIndexName,
        query,
        params: {
          filters: "content_type:product",
          hitsPerPage: 8,
        },
      },
    ]
  }

  function queryParamsToObject(params: string) {
    const decodedParams = decodeURIComponent(params)
    const splitParams = decodedParams
      .split("&")
      .map((param) => param.split("="))
    return Object.fromEntries(splitParams)
  }

  function getFilterIndex(filter: keyof filterValueMapType, rawResults: any) {
    const filterQuery = FILTER_VALUE_MAP[filter][0]

    if (filterQuery) {
      return rawResults.findIndex((queryResult: any) => {
        const { params } = queryResult
        const queryObject = queryParamsToObject(params)

        return queryObject.filters === filterQuery
      })
    }

    return -1
  }

  const search = debounce(() => {
    const searchValue = decodeURIComponent(searchInput.value)
    const algoliaQuery = createQueries(searchValue)

    algoliaClient.client
      .multipleQueries(algoliaQuery)
      .then((response: any) => {
        const { results } = response
        canShowResults.value = true
        allRawResults.value = results

        allResults.value = results.map((rawQueryResult: any) => {
          return rawQueryResult.hits.map((rawResult: any) =>
            parseRawSearchResult({ rawHit: rawResult, includePageText: true }),
          )
        })

        rawCategoryResults.value =
          allResults.value[getFilterIndex("categories", results)]
        rawPageResults.value =
          allResults.value[getFilterIndex("articles", results)]
        rawHelpResults.value = allResults.value[getFilterIndex("help", results)]
        rawProductResults.value =
          allResults.value[getFilterIndex("products", results)]
      })
      .finally(() => {
        const message =
          allResults.value.length > 0
            ? `${allResults.value.length} search results found`
            : "No search results found"

        notificationStore.announce(message, { live: "polite" })
      })
  }, 300)

  // Keyboard navigation
  // ----

  function getFilterKeyFromQuery(query: string) {
    const filterValues = Object.values(FILTER_VALUE_MAP)
    const filterIndex = filterValues.findIndex((filter) => filter[0] === query)
    const filterName = Object.keys(FILTER_VALUE_MAP)[filterIndex]
    return filterName
  }

  const groupedResultIds = computed(() => {
    return allRawResults.value.reduce((acc, queryResult) => {
      const group = {
        name: "",
        resultIds: [],
        ariaId: "",
      }

      const queryObject = queryParamsToObject(queryResult.params)
      const groupName = getFilterKeyFromQuery(queryObject.filters)

      group.resultIds = queryResult.hits.map((result: any) => result.objectID)
      group.ariaId = ariaSectionIdMap[groupName as keyof ariaSectionIdMapType]
      group.name = groupName

      acc.push(group)
      return acc
    }, [])
  })

  const allResultIds = computed(() => {
    const nestedIds = allResults.value.flatMap((resultQuery) => {
      return resultQuery.map((result: any) => result.id)
    })
    return nestedIds
  })

  function onInputKeydown(e: KeyboardEvent) {
    const { key } = e

    if (key === "ArrowDown") {
      focusNext()
    } else if (key === "ArrowUp") {
      focusPrevious()
    } else if (key === "Escape") {
      closeDropdown()
    } else if (key === "Enter") {
      onEnter(e)
    }
  }

  function onEnter(e: KeyboardEvent) {
    e.preventDefault()

    if (focusedIndex.value > -1) {
      // trigger click on link
      const targetEl = searchResultsEl.value?.querySelector(
        `#${ariaActiveDescendent.value}`,
      ) as HTMLElement

      targetEl.click()
    } else {
      // search query
      onSearch()
    }
  }

  function focusNext() {
    const totalResultCount = allResults.value.flat().length
    if (focusedIndex.value < totalResultCount - 1) {
      focusedIndex.value++
    }
  }

  function focusPrevious() {
    if (focusedIndex.value > -1) {
      focusedIndex.value--
    }
  }

  const focusedId = computed(() => {
    return allResultIds.value[focusedIndex.value]
  })

  watch(focusedIndex, () => {
    const currentGroup = groupedResultIds.value.find((group: any) => {
      return group.resultIds.includes(focusedId.value)
    })

    if (currentGroup) {
      const focusedItemGroupIndex = currentGroup.resultIds.findIndex(
        (resultId: string) => resultId === focusedId.value,
      )

      ariaActiveDescendent.value = `${currentGroup.ariaId}-${focusedItemGroupIndex}`
      ariaOwns.value = `${currentGroup.ariaId}s`
    }
  })

  // Search Submit
  // ----

  function onSubmit(e: Event) {
    e.preventDefault()
    onSearch()
  }

  const onSearch = async (e?: Event) => {
    e?.preventDefault()

    if (searchInput.value) {
      await navigateTo({
        path: `/search`,
        query: {
          q: searchInput.value,
        },
      })
    }
  }

  const onResultClick = (searchResult: any) => {
    $csGtm.trackEvent({
      event: "search-result-click",
      result_url: searchResult.permalink,
      result_page_type: searchResult.pageType,
      query: searchInput.value,
    })
  }

  watch(
    () => route?.fullPath,
    () => {
      closeDropdown()
    },
  )

  const inputAriaLabel = computed(() => {
    const totalResultCount = allResults.value.flat().length

    if (totalResultCount > 1) {
      return `${totalResultCount} search results`
    }
    if (totalResultCount === 1) {
      return `${totalResultCount} search result`
    }
    if (searchInput.value && totalResultCount === 0) {
      return `No search results`
    }
    return "Search Fragrances and Supplies"
  })
</script>

<style lang="scss">
  .search-bar {
    width: 100%;
    box-sizing: border-box;

    .search-input {
      @include type("sm");
      font-family: inherit;
      font-feature-settings: inherit;
      outline: none;
      padding-left: $base-spacing * 17;
      width: 100%;
      border-radius: 25px;
      border: 1px solid $gray-200;
      height: $base-spacing * 12;
      background: $gray-tint;
      box-sizing: border-box;
      transition:
        background-color ease-in-out 180ms,
        box-shadow ease-in-out 180ms,
        border-radius 180ms ease-in-out,
        outline 180ms ease-in-out;

      &:focus,
      &.open {
        background: #ffffff;
        box-shadow: 0 4px 9px rgba(0, 20, 40, 0.2);
        border-color: transparent;
      }
      &::placeholder {
        color: $gray-text;
      }
      @include viewport("sm") {
        width: 100%;
      }
      @include viewport("mini", "sm") {
        padding: $space-s;
        padding-left: $base-spacing * 10;
        border-radius: $border-radius;
      }
    }

    .search-bar-wrap {
      position: relative;
      display: flex;
      align-items: stretch;
    }

    .submit-search {
      box-sizing: border-box;
      font-weight: bold;
      opacity: 0;
      border: none;
      background: $blue-shape;
      color: white;
      border-radius: $border-radius;
      padding: 0 $space-s;
      position: absolute;
      z-index: 10;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &:focus {
        pointer-events: all;
        opacity: 1;
      }
      &.show {
        opacity: 1;
      }
    }

    .search-bar-icon-wrap {
      pointer-events: none;

      display: flex;
      align-items: center;
      color: $gray-600;
      position: absolute;
      height: 100%;
      left: $base-spacing * 5;

      @include viewport("mini", "sm") {
        left: $base-spacing * 3;
      }
    }

    .search-bar-icon {
      width: $base-spacing * 6;
      height: $base-spacing * 6;

      @include viewport("mini", "sm") {
        width: $base-spacing * 5;
        height: $base-spacing * 5;
      }
    }

    .dropdown {
      background: white;
      position: absolute;
      left: 0;
      top: 60%;
      width: 100%;
      box-shadow: 0 10px 15px -5px rgba(0, 20, 40, 0.2);

      .container {
        margin-top: 0;
        margin-bottom: 0;
      }

      .columns-container {
        display: flex;
        gap: 24px;
        padding-top: 32px;
        padding-bottom: 32px;

        .search-column {
          flex: 1 0 0;
        }
        .search-results {
          a {
            &.active {
              outline: 2px solid $blue-300;
            }
          }
        }
      }
      .dropdown-products {
        .products-container {
          padding-top: 32px;
          padding-bottom: 32px;
        }
        .products-list {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
          grid-template-rows: auto;
          grid-auto-rows: 0;
          column-gap: 20px;

          overflow: hidden;
          width: 100%;
          margin-bottom: 32px;
        }
        .product-result {
          display: inline-block;
          margin: 2px; // room for .active outline
          width: 100%;
          cursor: pointer;

          &.active {
            outline: 2px solid $blue-400;
          }
        }
      }
      .column-title {
        display: block;
        font-weight: 600;
        margin-bottom: 8px;
        color: $onyx;

        svg {
          position: relative;
          top: -1px;
          left: -3px;
          display: inline-block;
          height: 0.9em;
          width: 0.9em;
          vertical-align: middle;
        }
      }

      .query-button {
        display: block;
        @include type("sm");
        margin: 0 auto;

        &.active {
          background-color: $blue-700;
        }
      }
    }
  }
</style>
