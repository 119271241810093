<template>
  <NuxtLink
    :prefetch="false"
    :to="href"
    :style="{ backgroundImage: `url(${image})` }"
    :class="[
      'card',
      darkText && 'text-dark',
      overlay && 'overlay',
      centerText && 'center-text',
    ]"
  >
    <span class="card-text">
      {{ title }}
    </span>
  </NuxtLink>
</template>

<script lang="ts" setup>
  const props = defineProps<{
    element: AlchemyMainMenuCard
  }>()

  const { getValue } = useAlchemy()

  const title = getValue(props.element, "title")
  const href = getValue<string>(props.element, "link")
  const image = getValue(props.element, "image")
  const darkText = getValue(props.element, "dark_text")
  const overlay = getValue(props.element, "overlay")
  const centerText = getValue(props.element, "center_text")
</script>

<style lang="scss" scoped>
  .card {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    border-radius: $border-radius;
    box-shadow: $shadow;
    width: 100%;
    padding: 1.5em 1em;
    flex: 1;
    box-sizing: border-box;
    @include type("h4");
    text-align: center;
    text-decoration: none;
    color: white;
    text-shadow: 1px 1px rgba($onyx, 0.5);

    background-size: cover;
    background-position: center center;
    transition:
      transform 170ms ease-in-out,
      box-shadow 170ms ease-in-out;
    will-change: transform, box-shadow;
    backface-visibility: hidden;

    &.text-dark {
      color: $onyx;
      text-shadow: 1px 1px rgba(white, 0.5);
    }
    &.center-text {
      align-items: center;
    }
    &.overlay {
      &:after {
        content: " ";
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.25);
      }
      &.text-dark:after {
        background: rgba(255, 255, 255, 0.25);
      }
    }

    &:first-child {
      margin-bottom: $base-spacing * 4;
    }
    &:hover {
      transform: scale(1.05) translate3d(0, 0, 0) perspective(1px);
      box-shadow: $hover-shadow;
    }
    &:only-child {
      flex-basis: $base-spacing * 60;
      @include type("h3");
      margin-bottom: 0;
    }
    &:focus {
      outline: none;
      box-shadow: $focus-shadow;
    }
  }

  .card-text {
    position: relative;
    z-index: 10;
  }
</style>
