<template>
  <img
    class="h-[44px] w-[220px] hidden sm:block max-w-full"
    src="/logo-with-text.svg"
    alt="Candle making supplies from CandleScience"
  />
  <img
    class="h-[44px] w-[160px] block sm:hidden max-w-full"
    src="/logotype.svg"
    alt="Candle making supplies from CandleScience"
  />
</template>

<script setup>
  defineProps({
    text: {
      type: Boolean,
      default: false,
    },
  })
</script>
<script>
  export default {
    name: "CsLogo",
  }
</script>
